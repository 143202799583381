<template lang="pug">
.marquee-ticker(
  ref="wrapper"
  :class="{ 'marquee-ticker--pause-on-hover': pauseOnHover, 'marquee-ticker--overflowed': overflowed, 'marquee-ticker--masked': mask > 0 }"
  :style="{ '--masked': `${mask}px` }")
  .marquee-ticker__inner(
    ref="inner"
    :style="{ animationDuration: `${duration}s`, gap: `${gap}px` }")
    slot
    slot(v-if="cloned")
</template>

<script>
export default {
  props: {
    gap: { type: Number, default: 24 },
    speed: { type: Number, default: 50 },
    mask: { type: Number, default: 32 },
    overflowed: { type: Boolean, default: false },
    pauseOnHover: { type: Boolean, default: false }
  },

  data () {
    return {
      duration: 0,
      cloned: false
    }
  },

  watch: {
    '$slots.default': {
      immediate: true,
      handler () {
        this.refresh()
      }
    }
  },

  mounted () {
    this.refresh()
  },

  methods: {
    refresh () {
      const innerWidth = this.$refs.inner?.offsetWidth
      const wrapperWidth = this.$refs.wrapper?.offsetWidth
      if (!innerWidth || !wrapperWidth) return
      if (innerWidth > wrapperWidth) {
        this.duration = (innerWidth + this.gap) / this.speed
        this.cloned = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.marquee-ticker {
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  &--overflowed {
    overflow: visible;
  }
  &--masked {
    mask-image: linear-gradient(to right, transparent, black var(--masked, 24px), black calc(100% - var(--masked, 24px)), transparent);
  }
  &--pause-on-hover {
    &:hover {
      > div {
        animation-play-state: paused;
      }
    }
  }
  &__inner {
    width: fit-content;
    animation: marquee 0s linear infinite;
    display: flex;
    align-items: center;
  }
}
</style>
