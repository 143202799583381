<template lang="pug">
nuxt-link.sign-up-cta(
  v-if="!logged"
  :to="{ name: 'register' }"
  v-click="track"
  title="Sign up")
  span Join over <strong>3,000+</strong>
    br
    | brands &amp; retailers
  strong Sign up
  i.bx.bx-right-arrow-alt
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    zone: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      logged: 'authentication/logged'
    })
  },

  methods: {
    track () {
      this.$amplitude.track('REBRANDING_PAGE_ACTION', {
        type: 'click_register',
        zone: this.zone || this.$route.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-up-cta {
  background: #f1e8f3;
  display: flex;
  width: fit-content;
  padding: 8px 24px 8px 8px;
  border-radius: 1000px;
  align-items: center;
  gap: 4px;
  > span {
    background: white;
    border-radius: 1000px;
    line-height: 135%;
    color: $--color-text-regular;
    font-size: 14px;
    padding: 12px 24px;
    text-align: center;
  }
  > strong {
    font-weight: $--font-weight-bold;
    color: $--color-primary;
    font-size: 20px;
    margin-left: 16px;
    line-height: 150%;
  }
  > i {
    font-size: 32px;
    color: $--color-primary;
    animation: pd-shaker 2s infinite;
  }
}
</style>
