<template lang="pug">
section.introduction(@mouseleave="collapse(true)")
  .pd-fill
    .introduction__layout(@click="toggle(null)" :data-paused="!!expanding")
      h2.pd-h2.introduction__title Unified ecosystem
        br
        | for omnichannel growth

      .introduction__ring(data-level="1")
      .introduction__ring(data-level="2")
      .introduction__ring(data-level="3")

      article.introduction__node.pod-express(
        @mouseenter="expand('express')"
        @mouseleave="collapse"
        @click.stop="toggle('express')"
        :class="{ 'introduction__node--expanded': expanding === 'express' }")
        figure
          img(src="/img/landing/symbol.express.svg" alt="Pod Express" width="40" height="40")
          figcaption Pod<br>Express
        p A full-service national distribution solution for brands and retailers.&nbsp;
        p
          strong Control your cost.

      article.introduction__node.pod-direct(
        @mouseenter="expand('direct')"
        @mouseleave="collapse"
        @click.stop="toggle('direct')"
        :class="{ 'introduction__node--expanded': expanding === 'direct' }")
        figure
          figcaption Pod<br>Direct
          img(src="/img/landing/symbol.direct.svg" alt="Pod Direct" width="40" height="40")
        p The most scalable and affordable marketplace solution for wholesale grocery distribution.

      article.introduction__node.pod-freight(
        @mouseenter="expand('freight')"
        @mouseleave="collapse"
        @click.stop="toggle('freight')"
        :class="{ 'introduction__node--expanded': expanding === 'freight' }")
        figure.reversed
          figcaption Pod<br>Freight
          img(src="/img/landing/symbol.freight.svg" alt="Pod Freight" width="40" height="40")
        p Streamline your inventory shipments with our quick and easy freight solution that saves you time and money.

      article.introduction__node.pod-insights(
        @mouseenter="expand('insights')"
        @mouseleave="collapse"
        @click.stop="toggle('insights')"
        :class="{ 'introduction__node--expanded': expanding === 'insights' }")
        figure
          img(src="/img/landing/symbol.insights.svg" alt="Pod Insights" width="40" height="40")
          figcaption Pod<br>Insights
        p Visualize your business in real-time. Completely free. Completely customized.

      article.introduction__node.pod-capital(
        @mouseenter="expand('capital')"
        @mouseleave="collapse"
        @click.stop="toggle('capital')"
        :class="{ 'introduction__node--expanded': expanding === 'capital' }")
        figure
          img(src="/img/landing/symbol.capital.svg" alt="Pod Capital" width="40" height="40")
          figcaption Pod<br>Capital
        p Get your business moving and accelerate growth with quick &amp; easy access to funding.&nbsp;
        p
          strong Get prequalified in seconds

      article.introduction__node.pod-3pl(
        @mouseenter="expand('3pl')"
        @mouseleave="collapse"
        @click.stop="toggle('3pl')"
        :class="{ 'introduction__node--expanded': expanding === '3pl' }")
        figure
          img(src="/img/landing/symbol.3pl.svg" alt="Pod 3PL" width="40" height="40")
          figcaption Pod 3PL
        p Fast and efficient fulfillment solution for your omnichannel growth.

      article.introduction__node.pod-pax(
        @mouseenter="expand('pax')"
        @mouseleave="collapse"
        @click.stop="toggle('pax')"
        :class="{ 'introduction__node--expanded': expanding === 'pax' }")
        figure
          img(src="/img/landing/symbol.pax.svg" alt="Pod 3PL" width="40" height="40")
          figcaption Pod Pax
        p Scale your product line with the perfect co-manufacturer.

      article.introduction__node.pod-cost(
        @mouseenter="expand('cost')"
        @mouseleave="collapse"
        @click.stop="toggle('cost')"
        :class="{ 'introduction__node--expanded': expanding === 'cost' }")
        figure
          img(src="/img/landing/symbol.cost.svg" alt="Pod C.O.S.T." width="40" height="40")
          figcaption Pod COST
        p Cooperative of Sustainable Transportation program, to lower your inbound freight expense with consolidated FTL inbounds.

      article.introduction__node.pod-podcast(
        @mouseenter="expand('podcast')"
        @mouseleave="collapse"
        @click.stop="toggle('podcast')"
        :class="{ 'introduction__node--expanded': expanding === 'podcast' }")
        figure
          img(src="/img/landing/symbol.podcast.svg" alt="Pod Podcast" width="40" height="40")
          figcaption Pod<br>Podcast
        p Tune in for real talk with CPG founders, hosted by Peter Gialantzis.
</template>

<script>
export default {
  data () {
    return {
      expanding: null,
      lastUpdated: 0,
      isTouchable: false
    }
  },

  watch: {
    expanding (val) {
      if (val) {
        this.$amplitude.track('REBRANDING_PAGE_ACTION', {
          type: 'view_service_node',
          zone: 'home_introduction_section',
          slug: `pod-${val}`
        })
      }
    }
  },

  mounted () {
    this.checkCompatibility()
  },

  methods: {
    checkCompatibility () {
      this.isTouchable = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0)
    },

    expand (node) {
      if (this.isTouchable) return
      this.expanding = node
      this.lastUpdated = Date.now()
    },

    collapse (force = false) {
      if (!force) {
        if (this.isTouchable) return
        if (Date.now() - this.lastUpdated < 300) return
      }
      this.expanding = null
    },

    toggle (node) {
      this.expanding = this.expanding === node ? null : node
      this.lastUpdated = Date.now()
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes orbiting {
  from { transform: rotate(0deg) translateX(var(--orbit-radius)) rotate(0deg); }
  to { transform: rotate(360deg) translateX(var(--orbit-radius)) rotate(-360deg); }
}

.introduction {
  --orbit-state: running;
  --orbit-duration: 30s;
  --orbit-size: clamp(640px, 90vh, 120vw);
  --orbit-radius: calc(var(--orbit-size) / 2);
  &__layout {
    position: relative;
    z-index: 1;
    background: $--color-primary;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    border-radius: 32px;
    max-height: 920px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 24px;
    height: calc(100vh - 64px - 24px);
    @include media(sm) {
      min-height: 800px;
    }
    @include media(md) {
      padding: 48px;
      height: calc(100vh - 96px - 24px);
    }
    &[data-paused] {
      --orbit-state: paused;
      .introduction__title {
        opacity: .5;
      }
      .introduction__node:not(.introduction__node--expanded) {
        opacity: .8;
      }
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 40%;
      height: 120%;
      left: 0;
      right: 0;
      background: radial-gradient(50% 50% at 50% 50%, rgba(#FCA37E, .25) 0%, rgba($--color-primary, 0.00) 100%);
      z-index: -1;
    }
  }

  &__title {
    position: relative;
    z-index: 3;
    font-size: clamp(40px, 5vw, 80px) !important;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    &:before {
      content: '';
      position: absolute;
      top: -25%;
      bottom: -25%;
      left: 0;
      right: 0;
      z-index: -2;
      background: radial-gradient(50% 50% at 50% 50%, $--color-primary 25%, rgba($--color-primary, 0.00) 100%);
    }
  }

  &__ring {
    position: absolute;
    border: 1px solid rgba(white, .2);
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation-delay: var(--orbit-delay, 0s);
    z-index: -1;
    pointer-events: none;
    &[data-level="1"] {
      width: var(--orbit-size);
      height: var(--orbit-size);
    }
    &[data-level="2"] {
      width: calc(var(--orbit-size) / 1.618);
      height: calc(var(--orbit-size) / 1.618);
    }
    &[data-level="3"] {
      width: calc(var(--orbit-size) / 1.618 / 1.618);
      height: calc(var(--orbit-size) / 1.618 / 1.618);
    }
  }

  &__node {
    --node-radius: 32px;
    --node-width: 160px;
    --node-height: calc(var(--node-radius) * 2);
    --node-delta: calc(var(--node-radius) * -1);
    cursor: pointer;
    background: white;
    width: var(--node-width);
    height: var(--node-height);
    border-radius: var(--node-radius);
    position: absolute;
    padding: calc(var(--node-radius) * 0.4) 1em;
    pointer-events: all;
    overflow: hidden;
    transition: all .3s ease-in-out;
    margin-top: calc(var(--node-height) / -2);
    margin-left: calc(var(--node-width) / -2);
    top: 50%;
    left: 50%;
    animation: orbiting var(--orbit-duration) linear infinite;
    animation-play-state: var(--orbit-state);
    @include media(xs-only) {
      --node-width: calc(var(--node-radius) * 2);
      --node-height: calc(var(--node-radius) * 2);
      padding: calc(var(--node-radius) * 0.4);
    }
    &:nth-of-type(1) {
      animation-delay: calc(var(--orbit-duration) / -4 * 1);
      --orbit-radius: calc(var(--orbit-size) / 2 / 1.618);
    }
    &:nth-of-type(2) {
      animation-delay: calc(var(--orbit-duration) / -4 * 2);
      --orbit-radius: calc(var(--orbit-size) / 2 / 1.618);
    }
    &:nth-of-type(3) {
      animation-delay: calc(var(--orbit-duration) / -4 * 3);
      --orbit-radius: calc(var(--orbit-size) / 2 / 1.618);
    }
    &:nth-of-type(4) {
      animation-delay: calc(var(--orbit-duration) / -4 * 4);
      --orbit-radius: calc(var(--orbit-size) / 2 / 1.618);
    }
    &:nth-of-type(5) {
      animation-direction: reverse;
      --orbit-duration: 24s;
      animation-delay: calc(var(--orbit-duration) / -5 * 1);
    }
    &:nth-of-type(6) {
      animation-direction: reverse;
      --orbit-duration: 24s;
      animation-delay: calc(var(--orbit-duration) / -5 * 2);
    }
    &:nth-of-type(7) {
      animation-direction: reverse;
      --orbit-duration: 24s;
      animation-delay: calc(var(--orbit-duration) / -5 * 3);
    }
    &:nth-of-type(8) {
      animation-direction: reverse;
      --orbit-duration: 24s;
      animation-delay: calc(var(--orbit-duration) / -5 * 4);
    }
    &:nth-of-type(9) {
      animation-direction: reverse;
      --orbit-duration: 24s;
      animation-delay: calc(var(--orbit-duration) / -5 * 5);
    }
    &--expanded {
      width: 240px;
      padding: 24px;
      height: 240px;
      z-index: 4;
      box-shadow: 0 24px 48px 0 rgba($--color-primary, 1);
      @include media(xs-only) {
        transform: translate(-50%, -50%) scale(1.1) !important;
        transform-origin: center;
        margin: 0;
      }
    }
    figure {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      gap: 12px;
      > * {
        min-width: 0;
      }
      img {
        flex: 0 calc(var(--node-radius) * 1.2);
        width: calc(var(--node-radius) * 1.2);
        min-width: calc(var(--node-radius) * 1.2);
        height: auto;
      }
      figcaption {
        font-size: 16px;
        line-height: 19px;
        min-width: max-content;
        font-weight: $--font-weight-bold;
        text-transform: uppercase;
        color: $--color-text-regular;
        text-align: left;
        gap: 4px;
        &:first-child {
          text-align: right;
          margin-left: .5em;
          @include media(sm-down) {
            text-align: left;
            order: 1;
            margin-left: 0;
          }
        }
      }
    }
    p {
      margin: 12px 0 0 0;
      text-align: left;
      color: $--color-text-secondary;
      min-width: 192px;
      line-height: 150%;
      font-size: 15px;
      letter-spacing: -0.02em;
      strong {
        color: $--color-primary;
      }
    }
  }
}
</style>
