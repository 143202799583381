<template lang="pug">
svg.timer-circle(
  :class="{ 'timer-circle--reset-on-hover': resetOnHover }"
  :viewBox="viewBox"
  width="100%"
  height="100%"
  @mouseenter="unbind"
  @mouseleave="bind")
  circle(
    :key="hash"
    :style="{ animationDuration: `${duration}ms` }"
    :stroke="overlayColor"
    :stroke-width="strokeWidth"
    cx="16"
    cy="16"
    r="16"
    fill="none")
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 10000
    },

    overlayColor: {
      type: String, default: 'white'
    },

    strokeWidth: {
      type: Number,
      default: 1
    },

    resetOnHover: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      interval: null,
      updated: Date.now()
    }
  },

  computed: {
    viewBox () {
      return `${-this.strokeWidth / 2} ${-this.strokeWidth / 2} ${32 + this.strokeWidth} ${32 + this.strokeWidth}`
    },

    hash () {
      return `${this.duration}-${this.updated}`
    }
  },

  watch: {
    duration: 'bind'
  },

  mounted () {
    this.bind()
  },

  beforeDestroy () {
    this.unbind()
  },

  methods: {
    bind () {
      this.unbind()
      this.updated = Date.now()
      this.interval = setInterval(() => {
        this.$emit('timed')
      }, this.duration)
    },

    unbind () {
      if (this.interval) clearInterval(this.interval)
      this.interval = null
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes dash {
  0% { stroke-dashoffset: 100; }
  100% { stroke-dashoffset: 0; }
}
.timer-circle {
  transform: rotate(-90deg);
  &--reset-on-hover:hover {
    circle {
      display: none;
    }
  }
  circle {
    stroke-dasharray: 100 100;
    stroke-dashoffset: 50;
    transition: stroke-dashoffset .2s linear;
    animation-name: dash;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
</style>
