<template lang="pug">
section.hero
  .pd-container.framed
    .hero__introduction
      .hero__welcome
        h1.pd-h1.hero__title The&nbsp;
          br.hidden-sm-and-up
          typing-text.hero__typing(:texts="typings") innovative
          //- span.hero__cursor _
          br
          span path to retail.
        p.hero__description Get innovation delivered with&nbsp;
          br.hidden-md-and-up
          | the industry's largest&nbsp;
          br.hidden-sm-and-down
          | catalog of&nbsp;
          br.hidden-md-and-up
          | best-in-class brands.
        sign-up-cta.hero__cta(zone="home-hero-section")

      .hero__symbol.hidden-sm-and-down
        figure

    .hero__partnership
      template(v-if="partners.length")
        span Our retailers
        marquee-ticker.hero__ticker
          .hero__partners
            div(
              v-for="i, k in partners"
              :key="k"
              v-image="i.logo_medium_url")
</template>

<script>
import { mapGetters } from 'vuex'

import TypingText from '~/components/TypingText'
import SignUpCta from './SignUpCta'
import MarqueeTicker from './MarqueeTicker'

export default {
  components: {
    TypingText,
    MarqueeTicker,
    SignUpCta
  },

  props: {
    partners: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      typings: [{
        text: 'innovative',
        style: { color: '#9B0B54' }
      }, {
        text: 'transparent',
        style: { color: '#9B0B54' }
      }, {
        text: 'no deductions',
        style: { color: '#9B0B54' }
      }, {
        text: 'intelligent',
        style: { color: '#9B0B54' }
      }, {
        text: 'consolidated',
        style: { color: '#9B0B54' }
      }, {
        text: 'strategic',
        style: { color: '#9B0B54' }
      }]
    }
  },

  computed: {
    ...mapGetters({
      logged: 'authentication/logged'
    })
  }
}
</script>

<style lang="scss" scoped>
@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero {
  padding: 40px 0 64px 0;
  text-align: center;
  @include media(md) {
    text-align: left;
  }
  @include media(lg) {
    padding: 0 0 64px 0;
  }
  &__introduction {
    @include media(md) {
      display: flex;
      align-items: center;
    }
    @include media(lg) {
      align-items: flex-end;
    }
    > * {
      min-width: 0;
    }
  }
  &__title {
    margin: 0;
    @include media(lg) {
      font-size: 72px !important;
    }
  }
  &__cursor {
    color: $--color-primary;
  }
  &__description {
    font-size: 18px;
    line-height: 160%;
    color: $--color-text-secondary;
  }
  &__cta {
    margin: 32px auto 0;
    @include media(md) {
      margin: 48px 0 0 0;
    }
  }

  &__welcome {
    @include media(md) {
      flex: 0 580px;
    }
    @include media(lg) {
      flex: 0 640px;
    }
  }
  &__symbol {
    flex: 1;
    figure {
      aspect-ratio: 1;
      width: 100%;
      max-width: 440px;
      width: auto;
      mask-image: url('/img/landing/mask.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      display: block;
      margin: 0 auto;
      padding: 0;
      background: linear-gradient(90deg, $--color-primary 35%, $--color-primary-light 50%, $--color-primary 65%);
      background-size: 400% 400%;
      animation: gradient 4s ease-in-out infinite;
    }
  }

  &__partnership {
    margin-top: 48px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    min-height: 116px;
    @include media(md) {
      min-height: 72px;
      align-items: center;
      flex-direction: row;
      gap: 36px;
    }
    > span {
      min-width: fit-content;
      font-size: 16px;
      color: $--color-text-secondary;
    }
    > * {
      min-width: 0;
    }
  }

  &__partners {
    flex: 1;
    display: flex;
    gap: 24px;
    width: fit-content;
    > div {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 160px;
      height: 60px;
      @include media(md) {
        width: 180px;
        height: 72px;
      }
    }
  }

  &__ticker {
    width: 100%;
  }
}
</style>
