<template lang="pug">
section.testimonials
  .testimonials__layout
    .pd-container.framed
      .testimonials__header
        h2.pd-h2.testimonials__title We work with&nbsp;
          br.hidden-sm-and-up
          | the nation's&nbsp;
          br
          | most innovative partners.

        sign-up-cta.testimonials__cta(zone="home-testimonials-section")

      .testimonials__content
        article.testimonials__reader(v-if="current")
          transition(name="fade" mode="out-in")
            .testimonials__reader-content(:key="current.id")
              p(:data-lines="current.lines" v-text="current.content")

          .testimonials__reader-footer
            .testimonials__reader-logo(
              v-if="current.logo_small_url"
              v-image="current.logo_small_url"
              :alt="current.name")
            .testimonials__reader-about
              .testimonials__reader-broker(
                v-text="current.broker"
                v-if="current.broker")
              .testimonials__reader-company
                span(v-text="current.name")
                a.testimonials__reader-link(
                  v-if="current.link"
                  :href="current.link"
                  target="_blank"
                  rel="noopenner"
                  :title="current.name")
                  i.bx.bx-link-external
            .testimonials__reader-actions
              button.testimonials__reader-back(
                @click.prevent="back")
                i.bx.bx-left-arrow-alt
              button.testimonials__reader-next(
                @click.prevent="next")
                i.bx.bx-right-arrow-alt
                timer-circle(:duration="5000" @timed="go(1)" resetOnHover ref="timer")

          .testimonials__reader-explore
            nuxt-link(
              v-click="() => track('view_all_testimonials')"
              :to="{ name: 'testimonials' }" title="View all testimonials")
              span View all testimonials
              i.bx.bx-right-arrow-alt

        .testimonials__up-next.hidden-sm-and-down(v-if="nexts.length")
          .testimonials__peek(
            v-for="i, k in nexts"
            :key="k"
            @click="go(k + 1)")
            .testimonials__peek-logo(
              v-if="i.logo_small_url"
              v-image="i.logo_small_url"
              :alt="i.name")
            .testimonials__peek-preview
              .testimonials__peek-author
                span(v-if="i.broker") {{ i.broker }} &nbsp;·
                strong(v-text="i.name")
                a.testimonials__peek-link(
                  v-if="i.link"
                  :href="i.link"
                  target="_blank"
                  rel="noopenner"
                  :title="i.name")
                  i.bx.bx-link-external
              .testimonials__peek-content {{ i.content }}

      .testimonials__press-releases(v-if="articles.length")
        span As seen on
        marquee-ticker(pause-on-hover)
          .testimonials__articles
            article(
              v-for="i, k in articles"
              :key="k")
              a(
                v-tooltip="{ content: i.title, classes: ['tc'] }"
                :href="i.url"
                target="_blank"
                rel="noopenner"
                :title="i.title")
                img(
                  :src="`${i.logo}?pod`"
                  :alt="i.title"
                  width="164"
                  height="96"
                  loading="lazy")
</template>

<script>
import { PRESS_RELEASES } from '~/settings'

import SignUpCta from './SignUpCta'
import MarqueeTicker from './MarqueeTicker'
import TimerCircle from './TimerCircle'

export default {
  components: {
    MarqueeTicker,
    SignUpCta,
    TimerCircle
  },

  props: {
    records: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      articles: [...PRESS_RELEASES, ...PRESS_RELEASES],
      reading: 0
    }
  },

  computed: {
    current () {
      return this.records[this.reading]
        ? {
            ...this.records[this.reading],
            lines: Array(Math.floor(this.records[this.reading].content.length / 100)).fill(0).join('')
          }
        : null
    },

    nexts () {
      const start = this.reading + 1
      const end = start + 3
      return this.records.slice(start, end)
    }
  },

  methods: {
    go (delta) {
      let to = this.reading + delta
      if (to < 0) to = this.records.length - 1
      if (to >= this.records.length) to = 0
      this.reading = to
      this.$refs.timer?.bind()
    },

    next () {
      this.go(1)
      this.track('click_view_next_testimonial')
    },

    back () {
      this.go(-1)
      this.track('click_view_previous_testimonial')
    },

    track (type) {
      this.$amplitude.track('REBRANDING_PAGE_ACTION', {
        type,
        zone: 'home_testimonials_section'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.testimonials {
  overflow: hidden;
  &__layout {
    --size: 32px;
    background-color: #770B42;
    color: white;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: 64px 0 48px;
    @include media(md) {
      padding: 140px 0 80px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 32px;
    @include media(md) {
      align-items: center;
      flex-direction: row;
      gap: 48px;
    }
  }

  &__title {
    flex: 1;
    margin: 0;
  }

  &__press-releases {
    margin-top: 80px;
    display: flex;
    min-height: 72px;
    flex-direction: column;
    gap: 0px;
    @include media(md) {
      flex-direction: row;
      gap: 36px;
      align-items: center;
    }
    > span {
      min-width: fit-content;
      font-size: 16px;
    }
    > * {
      min-width: 0;
    }
  }

  &__articles {
    flex: 1;
    display: flex;
    width: fit-content;
    gap: 16px;
    @include media(md) {
      gap: 24px;
    }
    a {
      display: block;
      img {
        height: auto;
        min-width: 140px;
        @include media(md) {
          min-width: 160px;
        }
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    @include media(md) {
      flex-direction: row;
      gap: 96px;
    }
  }

  &__up-next {
    flex: 1;
    min-width: 0;
    max-width: 100%;
  }

  &__peek {
    flex: 1;
    display: flex;
    gap: 16px;
    padding: 20px 0;
    align-items: center;
    cursor: pointer;
    &:not(:first-child) {
      border-top: 1px solid rgba(white, .1);
    }
    &-logo {
      flex: 0 48px;
      height: 48px;
      min-width: 48px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: white;
      border-radius: 4px;
    }
    &-author {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
      > * + * {
        margin-left: .5em;
      }
      span {
        color: rgba(white, .8);
      }
      a {
        color: lighten($--color-primary, 50);
      }
    }
    &-preview {
      flex: 1;
      min-width: 0;
    }
    &-content {
      margin-top: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 16px;
      color: rgba(white, .8);
      min-width: 0;
    }
  }

  &__reader {
    background-repeat: no-repeat;
    background-position: top left;
    margin-top: 48px;
    flex: 2;
    max-width: 720px;
    min-width: 0;
    @include media(sm) {
      padding-top: 24px;
      padding-left: 52px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCA0MSAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNy44ODUyIDYuNDgzNjZDMTQuMzI5MSA2Ljc2MjUzIDExLjU3NDggNy44NDMxNCA5LjYyMjQ1IDkuNzI1NDlDNy43Mzk4IDExLjYwNzggNi43OTg0NyAxNC4xMTc2IDYuNzk4NDcgMTcuMjU0OUM2Ljc5ODQ3IDE4LjA5MTUgNi44MzMzMyAxOC43MTkgNi45MDMwNiAxOS4xMzczSDguMTU4MTZDOC41NzY1MyAxNy41MzM4IDkuODMxNjMgMTYuNzMyIDExLjkyMzUgMTYuNzMyQzEzLjY2NjcgMTYuNzMyIDE1LjE2NTggMTcuNDI5MiAxNi40MjA5IDE4LjgyMzVDMTcuNzQ1OCAyMC4xNDgxIDE4LjQwODIgMjEuOTI1OSAxOC40MDgyIDI0LjE1NjlDMTguNDA4MiAyNi40NTc1IDE3LjY0MTIgMjguMzM5OSAxNi4xMDcxIDI5LjgwMzlDMTQuNjQyOSAzMS4yNjggMTIuNjU1NiAzMiAxMC4xNDU0IDMyQzYuOTM3OTMgMzIgNC40Mjc3MiAzMC43OCAyLjYxNDggMjguMzM5OUMwLjg3MTU5OSAyNS44OTk4IDAgMjIuNjkyOCAwIDE4LjcxOUMwIDEzLjkwODUgMS40Mjk0MiA5LjcyNTQ5IDQuMjg4MjcgNi4xNjk5NEM3LjIxNjg0IDIuNTQ0NjYgMTEuNzQ5MiAwLjQ4ODAxOSAxNy44ODUyIDBWNi40ODM2NlpNNDAuNDc3IDYuNDgzNjZDMzYuOTkwNiA2Ljc2MjUzIDM0LjI3MTMgNy44NDMxNCAzMi4zMTg5IDkuNzI1NDlDMzAuNDM2MiAxMS42MDc4IDI5LjQ5NDkgMTQuMDgyOCAyOS40OTQ5IDE3LjE1MDNDMjkuNDk0OSAxNy45ODY5IDI5LjUyOTggMTguNjQ5MiAyOS41OTk1IDE5LjEzNzNIMzAuODU0NkMzMS4yNzMgMTcuNTMzOCAzMi40OTMyIDE2LjczMiAzNC41MTUzIDE2LjczMkMzNi4yNTg1IDE2LjczMiAzNy43NTc3IDE3LjQyOTIgMzkuMDEyOCAxOC44MjM1QzQwLjMzNzYgMjAuMTQ4MSA0MSAyMS45MjU5IDQxIDI0LjE1NjlDNDEgMjYuNDU3NSA0MC4yMzMgMjguMzM5OSAzOC42OTkgMjkuODAzOUMzNy4yMzQ3IDMxLjI2OCAzNS4yNDc1IDMyIDMyLjczNzIgMzJDMjkuNTI5OCAzMiAyNy4wMTk2IDMwLjc4IDI1LjIwNjYgMjguMzM5OUMyMy40NjM0IDI1Ljg5OTggMjIuNTkxOCAyMi42OTI4IDIyLjU5MTggMTguNzE5QzIyLjU5MTggMTMuOTA4NSAyNC4wMjEzIDkuNzI1NDkgMjYuODgwMSA2LjE2OTk0QzI5LjgwODcgMi41NDQ2NiAzNC4zNDEgMC40ODgwMTkgNDAuNDc3IDBWNi40ODM2NloiIGZpbGw9IiM5RjU1N0IiLz4NCjwvc3ZnPg0K);
    }
    &-explore {
      font-size: 18px;
      margin-top: 1.5em;
      text-align: right;
      color: white;
      a {
        padding: .5em 0;
        border-bottom: 1px solid rgba(white, .2);
      }
      i {
        font-size: 24px;
        vertical-align: middle;
      }
    }
    &-content {
      font-size: 22px;
      letter-spacing: -0.02em;
      color: rgba(white, 0.9);
      min-width: 0;
      @include media(md) {
        min-height: 220px;
      }
      p {
        margin: 0;
        line-height: 160%;
        @include media(sm-down) {
          font-size: 16px !important;
        }
        &[data-lines*="00"] {
          font-size: 1em;
        }
        &[data-lines*="000"] {
          font-size: .9em;
        }
        &[data-lines*="0000"] {
          font-size: .8em;
        }
        &[data-lines*="00000"] {
          font-size: .7em;
        }
        &[data-lines*="00000"] {
          font-size: .65em;
        }
      }
    }
    &-footer {
      display: flex;
      align-items: center;
      margin-top: 24px;
      gap: 14px;
      > * {
        min-width: 0;
      }
    }
    &-logo {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: white;
      flex: 0 48px;
      height: 48px;
      border-radius: 6px;
      @include media(md) {
        flex: 0 64px;
        height: 64px;
        border-radius: 8px;
      }
    }
    &-about {
      flex: 1;
      display: flex;
      gap: 4px;
      flex-direction: column;
      line-height: 150%;
      font-size: 14px;
      @include media(md) {
        font-size: 16px;
      }
      > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-company {
      font-weight: $--font-weight-semibold;
      font-size: 18px;
      @include media(md) {
        font-size: 20px;
      }
    }
    &-broker {
      color: rgba(white, .8);
    }
    &-link {
      vertical-align: middle;
      margin-left: 8px;
      color: lighten($--color-primary, 50);
    }
    &-actions {
      display: flex;
      gap: 12px;
      > * {
        appearance: none;
        border: 1px solid rgba(white, .2);
        border-radius: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(white, .8);
        background: none;
        cursor: pointer;
        position: relative;
        transition: border-color .2s ease-in-out;
        width: 48px;
        height: 48px;
        font-size: 28px;
        min-width: 0;
        flex: 0 48px;
        @include media(md) {
          flex: 0 60px;
          width: 60px;
          height: 60px;
          font-size: 36px;
        }
        &:hover {
          border-color: white;
        }
        svg {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
