<template lang="pug">
.pd-page.index
  hero-section#hero(:partners="partners")

  introduction-section#introduction

  services-section#services

  mission-section#mission

  testimonials-section#testimonials(
    :records="testimonials")

  quick-start-section#quick-start
</template>

<script>
import HeroSection from '~/components/landing/HeroSection'
import IntroductionSection from '~/components/landing/IntroductionSection'
import ServicesSection from '~/components/landing/ServicesSection'
import MissionSection from '~/components/landing/MissionSection'
import TestimonialsSection from '~/components/landing/TestimonialsSection'
import QuickStartSection from '~/components/landing/QuickStartSection'

export default {
  name: 'Index',

  components: {
    HeroSection,
    IntroductionSection,
    ServicesSection,
    MissionSection,
    TestimonialsSection,
    QuickStartSection
  },

  layout: 'landing',

  data () {
    return {
      allTestimonials: []
    }
  },

  fetch () {
    return this.$api.common
      .getTestimonials({
        sort: 'published_at',
        direction: 'desc',
        per_page: 120
      })
      .then(res => {
        this.allTestimonials = res
      })
      .catch(this.$debug.log)
  },

  head: {
    title: 'Homepage',
    meta: [
      { name: 'google-site-verification', content: 'WScF--pHCcvrD-IMvDKhV2cEDPF1SzkE_L7DhkUIf3s' }
    ]
  },

  computed: {
    testimonials () {
      return this.allTestimonials
        .filter(i => i.featured && i.content)
    },

    partners () {
      return this.allTestimonials
        .filter(i => !i.featured && i.logo_small_url)
        .sort((a, b) => {
          if (a.priority !== b.priority)
            return a.priority - b.priority
          return 0
        })
    }
  }
}
</script>
