<template lang="pug">
section.services
  .pd-container.framed
    .services__layout
      .services__card
        .services__card-tag Brands
        h3.pd-h3.services__card-title The only partner you need to grow your business - only and offline.
        .services__card-description
          ul
            li.pd-p Accelerate your sales and unlock access to a nationwide network of retailers through our national last-mile logistics.
            li.pd-p Leverage our proprietary real-time data and AI insights to make the right decisions at the right time.
            li.pd-p Stay in control with end-to-end supply chain visibility and autonomy.

        nuxt-link.services__card-cta.el-button.el-button--pd-white(
          @click="() => track('brands')"
          :to="{ name: 'services-brands' }"
          title="Pod for Brands")
          span Pod for Brands
          i.bx.bx-right-arrow-alt

      .services__card
        .services__card-tag Retailers
        h3.pd-h3.services__card-title Stay ahead of the curve with the fastest supply chain solution in the industry.
        .services__card-description
          ul
            li.pd-p Discover an ever-expanding catalog of innovative brands.
            li.pd-p Procure the latest products with unparalleled speed to shelf.
            li.pd-p Track SKU performance, inventory, orders and invoices with our AI-enabled software.

        nuxt-link.services__card-cta.el-button.el-button--pd-white(
          v-click="() => track('retailers')"
          :to="{ name: 'services-retailers' }"
          title="Pod for Retailers")
          span Pod for Retailers
          i.bx.bx-right-arrow-alt
</template>

<script>
export default {
  methods: {
    track (slug) {
      this.$amplitude.track('REBRANDING_PAGE_ACTION', {
        type: 'click_services_cta',
        zone: 'home_services_section',
        slug
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.services {
  padding: 48px 0;
  &__layout {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    @include media(md) {
      flex-direction: row;
    }
    @include media(lg) {
      gap: 32px;
    }
  }
  &__card {
    flex: 1;
    min-width: 0;
    min-height: 0;
    display: flex;
    flex-direction: column;
    background-position: top right;
    background-repeat: no-repeat;
    padding: 24px;
    border-radius: 24px;
    gap: 24px;
    background-size: auto 180px;
    @include media(sm) {
      padding: 32px;
      border-radius: 32px;
    }
    @include media(md) {
      gap: 32px;
      padding: 48px;
      border-radius: 48px;
      background-size: auto 220px;
    }
    &:first-child {
      background-color: #efe9e6;
      background-image: url('/img/landing/services.brands.webp');
    }
    &:last-child {
      background-color: #EFE2F1;
      background-image: url('/img/landing/services.retailers.webp');
    }
    &-tag {
      font-size: 16px;
      line-height: 120%;
      color: white;
      background: $--color-primary-dark;
      padding: 12px 16px;
      border-radius: 24px;
      width: fit-content;
    }
    &-title {
      margin: 2.75em 0 0 0;
      color: $--color-primary-dark;
      @include media(md) {
        min-height: 4.8em;
      }
    }
    &-description {
      flex: 1;
      ul {
        margin: 0;
        padding: 0 0 0 16px;
        li {
          margin: 0;
          + li {
            margin-top: .5em;
          }
        }
      }
    }
    &-cta {
      align-self: flex-end;
    }
  }
}
</style>
