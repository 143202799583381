<template lang="pug">
section.quick-start(v-if="!logged")
  .pd-container.framed
    .quick-start__layout
      .quick-start__about
        h2.pd-h2 We Deliver
          br
          | Innovation.

        .quick-start__social
          p Follow us
          ul
            li
              a(:href="LINKEDIN_URL" target="_blank" title="LinkedIn" @click="track('view_linkedin')")
                i.bx.bxl-linkedin
            li
              a(:href="FACEBOOK_URL" target="_blank" title="Facebook" @click="track('view_facebook')")
                i.bx.bxl-facebook
            li
              a(:href="INSTAGRAM_URL" target="_blank" title="Instagram" @click="track('view_instagram')")
                i.bx.bxl-instagram
            li
              a(:href="YOUTUBE_URL" target="_blank" title="YouTube" @click="track('view_youtube')")
                i.bx.bxl-youtube

        .quick-start__contact
          p Contact us
          a(:href="`mailto:${CONTACT_EMAIL}`" title="Email" @click="track('send_email')")
            span {{ CONTACT_EMAIL }}
            i.bx.bx-link-external

      .quick-start__content
        el-alert.quick-start__finished(
          v-if="finished"
          show-icon
          :closable="false"
          type="success"
          title="Thank you for contacting us!"
          description="A Pod representative will reach out to help get you started.")

        el-form.quick-start__form(
          v-else
          :rules="rules"
          :model="form"
          ref="form"
          v-loading="submitting"
          label-position="top"
          require-asterisk-position="right"
          @submit.native.prevent)
          .quick-start__form-row
            .quick-start__form-col
              el-form-item(prop="company_name" label="Company name")
                el-input(v-model="form.company_name" placeholder="Your company name")

          .quick-start__form-row
            .quick-start__form-col
              el-form-item(prop="business_email" label="Business email")
                el-input(v-model="form.business_email" placeholder="email@company.com")
            .quick-start__form-col
              el-form-item(prop="website" label="Website")
                el-input(v-model="form.website" placeholder="https://yourwebsite.com")

          .quick-start__form-row
            .quick-start__form-col
              el-form-item(prop="contact_type" label="Contact type")
                .quick-start__form-chips
                  el-radio(
                    v-for="type in contactTypes"
                    v-model="form.contact_type"
                    :key="type.id"
                    :label="type.id"
                    border) {{ type.name }}
            .quick-start__form-col.quick-start__form-col--fit
              el-button.quick-start__form-button(
                type="pd-primary"
                @click="submit")
                span Submit
                i.bx.bx-right-arrow-alt

</template>

<script>
import { mapGetters } from 'vuex'

import {
  LINKEDIN_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  YOUTUBE_URL,
  CONTACT_EMAIL
} from '~/settings'

export default {
  data () {
    return {
      LINKEDIN_URL,
      FACEBOOK_URL,
      INSTAGRAM_URL,
      YOUTUBE_URL,
      CONTACT_EMAIL,

      submitting: false,
      finished: false,
      form: {}
    }
  },

  computed: {
    ...mapGetters({
      logged: 'authentication/logged',
      options: 'master/mondayFormOptions'
    }),

    rules () {
      const { required, email } = this.$validators
      const _required = { ...required }
      return {
        company_name: _required,
        contact_type: _required,
        business_email: [_required, email],
        website: _required,
        agreement: {
          validator (rule, val, cb) {
            if (!val) return cb(new Error('Required'))
            cb()
          }
        }
      }
    },

    contactTypes () {
      return this.options?.inbound_registration_form?.contact_types || []
    },

    is () {
      const contactType = this.contactTypes
        .find(i => i.id === this.form.contact_type)
      const roleName = contactType?.name.toLowerCase() || ''
      return {
        vendor: roleName.includes('vendor'),
        broker: roleName.includes('broker'),
        retailer: roleName.includes('retailer')
      }
    }
  },

  watch: {
    'form.contact_type' () {
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    }
  },

  methods: {
    track (type) {
      this.$amplitude.track('REBRANDING_PAGE_ACTION', {
        type,
        zone: 'quick_start_section'
      })
    },

    submit () {
      return this.$refs.form?.validate(ok => {
        if (!ok) return
        this.submitting = true
        this.track('submit_quick_start_form')
        return this.$api.common
          .submitRequestAccessForm(this.form)
          .then(res => {
            this.finished = true
          })
          .finally(() => {
            this.submitting = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-start {
  font-size: 16px;
  padding: 64px 0 32px;
  @include media(sm) {
    padding: 80px 0 40px;
  }
  @include media(md) {
    padding: 120px 0;
  }
  &__layout {
    display: flex;
    flex-direction: column;
    gap: 48px;
    @include media(md) {
      flex-direction: row;
      gap: 96px;
    }
    > * {
      min-width: 0;
    }
  }
  &__about {
    h2 {
      color: $--color-primary-dark;
      margin: 0;
    }
    p {
      color: $--color-text-secondary;
    }
  }
  &__content {
    flex: 1;
  }
  &__social {
    margin-top: 48px;
    @include media(md) {
      margin-top: 64px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      gap: 12px;
      font-size: 36px;
      color: $--color-primary;
      a {
        display: flex;
        width: 64px;
        height: 64px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: $--border-light;
      }
    }
  }
  &__contact {
    margin-top: 44px;
    a {
      font-size: 24px;
      display: inline-flex;
      align-items: center;
      gap: 8px;
      color: $--color-primary-dark;
      padding: 8px 0;
      border-bottom: $--border-light;
      i {
        color: $--color-primary;
      }
    }
  }
  &__finished {
    border-radius: $--radius-lg;
    padding: 24px 16px;
    &::v-deep {
      .el-alert {
        &__icon {
          font-size: 36px;
          min-width: 44px;
        }
        &__content {
          min-width: 0;
        }
        &__title {
          font-size: 16px;
          line-height: 135%;
        }
        &__description {
          font-size: 14px;
          line-height: 135%;
        }
      }
    }
  }
  &__form {
    font-size: 14px;
    @include media(md) {
      font-size: 16px;
      margin-top: 1em;
    }
    &-row {
      display: flex;
      gap: 24px;
      + .quick-start__form-row {
        margin-top: 1em;
        @include media(md) {
          margin-top: 2.75em;
        }
      }
      @include media(md-down) {
        flex-direction: column;
      }
    }
    &-col {
      min-width: 0;
      flex: 1;
      &--fit {
        flex: 0 auto;
      }
    }
    &-chips {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      > * {
        min-width: 0;
        margin: 0 !important;
      }
    }
    &-button {
      @include media(sm-down) {
        width: 100%;
      }
      @include media(md) {
        margin-top: 30px;
      }
    }
    &::v-deep {
      .el-form-item {
        &__label {
          font-size: 1em;
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          gap: 4px;
          padding-bottom: .8em;
        }
        &__error {
          margin-top: 8px;
        }
      }
      .el-input {
        &__inner {
          border-top: none;
          border-left: none;
          border-right: none;
          border-radius: 0;
          padding: 12px 0;
          font-size: 20px;
          line-height: 160%;
          height: auto;
        }
      }
      .el-radio {
        border: 1px solid #ccc;
        height: auto;
        border-radius: 1000px;
        padding: 18px 24px;
        margin: 0;
        &.is-checked {
          background-color: $--color-primary;
          border-color: $--color-primary;
          .el-radio__label {
            color: white;
          }
        }
        &__input {
          display: none;
        }
        &__label {
          font-size: 1.175em;
          color: $--color-primary-dark;
          padding-left: 0;
        }
      }
    }
  }
}
</style>
